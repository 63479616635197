<template>
  <div v-if="isLoading" class="spinner-overlay">
    <spinner-component />
    <div class="spinner-text">
      {{ iceState ? iceStateMessage : 'Waiting For connection...' }}
    </div>
  </div>
  <video
    v-show="!isLoading"
    id="video"
    class="w-100"
    :style="{ height: '600px' }"
    autoplay
    playsinline
    muted
    controls
  />
</template>
<script>
import SpinnerComponent from '../../../shared/Components/Spinner';
import httpClient from '@/service/httpClient';
import WebRtc from 'src/services/webrtc';
import { MediaRTCConnection } from '../devices/ProjectiorRegion/webrtc';
import { mapGetters } from 'vuex';

export default {
  components: { SpinnerComponent },
  inject: ['toast'],
  props: ['serialNumber', 'deviceSettings'],
  emits: ['closeModal'],
  data() {
    return {
      video: null,
      videoParams: null,
      cssPhysicalProportion: 1,
      videoWidth: 640,
      rtcConfiguration: {},
      deviceMac: null,
      isLoading: true,
      rtcConn: null,
      answerSearching: null,
      iceState: null,
      iceStateMessage: null
    };
  },
  computed: {
    ...mapGetters(['organization', 'fcmToken'])
  },
  watch: {
    iceState(value) {
      console.log('ice state->', value);
      if (value === 'failed') {
        console.log('icestate failed', value);
        this.iceStateMessage = 'Live Stream Failed';
        this.$emit('closeModal');
      } else if (value === 'disconnected') {
        console.log('icestate disconnected', value);
        // this.isLoading = true;
        // this.iceStateMessage = 'Slow network detected. Trying to reconnect...';
        // this.toast.info('Slow network detected. Trying to reconnect...');
      } else if (value === 'connected') {
        this.iceStateMessage = 'Live Stream Connected';
        this.isLoading = false;
      } else if (value === 'checking') {
        this.isLoading = true;
        this.iceStateMessage = 'Checking For Connection...';
      }
    }
  },
  created() {
    this.deviceMac = this.serialNumber;
  },
  mounted() {
    this.video = document.getElementById('video');
    this.video.addEventListener('canplay', () => {
      this.play();
    });
    this.showStream();
  },
  beforeUnmount() {
    this.emitter.off(
      this.serialNumber,
      this.mediaConnection.connectToRemotePeer
    );
    this.mediaConnection.close();
    clearInterval(this.answerSearching);
  },
  methods: {
    play() {
      this.video.play();
    },
    async showStream() {
      // this.initRTCConnection();
      console.log('showStream');
      this.rtcConfiguration = await WebRtc.getRTCServers(this.deviceSettings.ice_options);
      this.mediaConnection = new MediaRTCConnection(
        this.serialNumber,
        this.organization,
        this.fcmToken,
        this.video,
        this.rtcConfiguration,
        this.setIceState
      );
      await this.mediaConnection.connectToRemotePeer();
      console.log(this.mediaConnection);
      this.emitter.on(
        this.serialNumber,
        this.mediaConnection.setRTCRemoteDescription.bind(this.mediaConnection)
      );
    },
    setIceState: function(value) {
      console.log('ice state', value);
      this.iceState = value;
    },
    setVideoParams() {
      const self = this;
      const modalBody = document.getElementById('stream-modal-body');
      setTimeout(() => {
        const { top, left, width, height } = self.video.getBoundingClientRect();
        const modalParams = modalBody?.getBoundingClientRect();
        self.videoParams = {
          top: Math.floor(top - modalParams.top),
          left: Math.floor(left - modalParams.left),
          width: Math.floor(width),
          height: Math.floor(height)
        };
        self.cssPhysicalProportion = self.videoWidth / width;
      }, 2000);
    }
  }
};
</script>
<style scoped>
.video-container {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.spinner-overlay {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  /* Semi-transparent background for the overlay */
  /* background: rgba(
    255,
    255,
    255,
    0.8
  );  */
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1;  */
  text-align: center;
  flex-direction: column;
}
.spinner-text {
  margin-top: 10px; /* Add some spacing between the spinner and text */
}
</style>
